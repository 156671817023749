import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  BackgroundBlur: Color.Surface.Floating.Background,

  Icon: Color.BodyText,

  NotificationBackground: Color.Primitive.Secondary,
  NotificationText: Color.Primitive.SecondaryContrast,
};
